function mobileMenu() {
  var body = document.body;
  var openedMenu = !body.classList.contains("scroll-lock");
  if (openedMenu) {
    body.classList.add("scroll-lock");
  } else {
    body.classList.remove("scroll-lock");
  }
}

document
  .querySelector("#openSidebarMenu")
  .addEventListener("click", mobileMenu);

document.querySelectorAll("#sidebarMenu > ul > li a").forEach(function (item) {
  //console.log(item);
  item.addEventListener("click", function (e) {
    //console.log(e.currentTarget);
    if (window.innerWidth < 1280) {
      if (e.currentTarget.classList.contains("has-submenu")) {
        e.preventDefault();
        e.currentTarget.parentNode.parentNode.classList.toggle("open-menu");
      }
    }
  });
});

const userIcon = document.querySelector(".js-breadcrumb__user");
const userDropdown = document.querySelector(".js-user__dropdown");
if (userIcon) {
  userIcon.addEventListener("click", () => {
    userDropdown.classList.toggle("hidden");
  });
}

const accordion = document.querySelectorAll(".Accordion");

if (accordion && accordion.length) {
  accordion.forEach((accordionInstance) => {
    const items = accordionInstance.querySelectorAll(".js-accordion__item");

    items.forEach((item) => {
      const title = item.querySelector(".js-accordion__header");

      title.addEventListener("click", () => {
        // Toggle current item
        toggle_item(item);
      });
    });

    const toggle_item = (item) => {
      const body = item.querySelector(".js-accordion__body");

      if (item.classList.contains("is-open")) {
        body.removeAttribute("style");
        item.classList.remove("is-open");
      } else {
        body.style.height = body.scrollHeight + "px";
        item.classList.add("is-open");
      }
      if (item.classList.contains("imagebar")) {
        body.classList.toggle("pb-96");
      }
    };
  });

  const backBtn = document.querySelector(".btn__container .button");

  let prevURL = document.referrer;
  if (prevURL.includes("about-us") || prevURL.includes("membership")) {
    backBtn.style.display = "block";
    backBtn.setAttribute("href", prevURL);
  } else {
    backBtn.style.display = "none";
  }
}
